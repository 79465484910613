<template>
  <div
    class="rounded-3xl bg-[#15B8AD] text-[#FFF] text-base tracking-widest font-semibold px-4 py-2  cursor-pointer"
    @click="goToApply"
  >
    {{ text }}
  </div>

  <SearchDialog
    :dialogApply="isShowDialog"
    @closeModalApply="closeModalApply"
  />
</template>

<script setup>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import useStore from "@/client/store/Customer";
import SearchDialog from "@/client/molecules/LayoutHome/SearchDialog.vue";

const { t } = useI18n();
const text = t("header.apply_here");

const uStore = useStore();
const isShowDialog = ref(false);

const goToApply = () => {
  isShowDialog.value = true;
};

const closeModalApply = () => {
  isShowDialog.value = false;
  uStore.isShowModalSearch = false;
};
</script>
<template>
  <Teleport to="body">
      <div>
          <v-row justify="center" align="center">
          <v-dialog
              class="apply-action__box"
              v-model="dialog"
              hide-overlay
              scrollable
              max-width="100%"
              height="400px"
          >
              <v-card class="header-action__card">
              <div class="search_component">
                  <SearchComponent :listGroupArea = 'store.getListGroupArea' :listCategory = 'store.getListCategory'/>
              </div>
              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                  color="blue-grey"
                  class="header-action__button button-apply"
                  fab
                  @click="closeModalApply"
                  >
                  <v-icon
                      class="header-action__icon icon-apply"
                      dark
                      color="black"
                  >
                      mdi-window-close
                  </v-icon>
                  </v-btn>
              </v-card-actions>
              </v-card>
          </v-dialog>
          </v-row>
      </div>
  </Teleport>
</template>

<script>
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import { reactive, ref } from "vue";
import { ROUTER_PATH } from "@/client/const";
import useAuth from "@/client/store/auth";
import { storeToRefs } from "pinia";
import { watch } from "vue";
import { ROUTE_NAME } from "@/client/const";
import useCustomer from "@/client/store/Customer";
import SearchComponent from "@/client/components/SearchComponent/SearchComponent.vue";

export default {
Name: "SearchDialog",
components: {
  SearchComponent
},
props: {
  dialogApply: {
  type: Boolean,
  default: false,
  },
  listLocation: {
      type: Object,
      required: true,
  },
  listCategory: {
      type: Object,
      required: true,
  },
},
setup(props, { emit }) {
  const store = useCustomer();
  store.fetchListCategory();
  store.fetchListGroupArea();
  const route = useRoute();
  const { t } = useI18n();
  const dialog = ref(false);

  watch(props, () => {
    dialog.value = props.dialogApply;
  });

  watch(dialog, () => {
    if (dialog.value === false) {
        store.isShowModalSearch = false;
        emit("closeModalApply");
    }
  });
  const formResearch = ref({
  group_area: "",
  grouparea: "",
  category: "",
  people: "",
  });
  const authState = useAuth();
  const listPeople = [
  { name: "ブース(個室)", id: '0-5' },
  { name: "~20人", id: '6-20' },
  { name: "21~40人", id: '21-40' },
  { name: "41~60人", id: '41-60' },
  { name: "61~100人", id: '61-100' },
  { name: "101人~", id: '101-' },
  ];
  const { isAuthenticated } = storeToRefs(authState);
  const listActionHeader = reactive([
  {
      id: 1,
      icon: "mdi-account",
      title: t("header.my_page"),
      link: `${ROUTER_PATH.MY_PAGES}`,
      background: "#297fca",
      isShow: isAuthenticated.value,
  },
  {
      id: 2,
      icon: "mdi-login",
      title: t("header.login"),
      link: `${ROUTER_PATH.LOGIN}`,
      isShow: !isAuthenticated.value,
      background: "#297fca",
  },
  {
      id: 3,
      // icon: "mdi-account-plus",
      icon: "fa-solid fa-user-plus",

      title: t("header.sign_up"),
      link: `${ROUTER_PATH.REGISTER}`,
      isShow: !isAuthenticated.value,
      background: "#297fca",
  },
  {
      id: 4,
      icon: "",
      title: t("header.apply_here"),
      link: ``,
      isShow: true,
      background: "#ff8a0d",
  },
  ]);
  const handleModel = (id) => {
  if (id === 4) {
      dialog.value = true;
  } else {
      dialog.value = false;
  }
  };
  const handelToResearch = () => {
    let param = "?grouparea="+formResearch.value.grouparea+"&category="+formResearch.value.category+"&people="+formResearch.value.people+"&group_area="+formResearch.value.group_area;
    location.href = '/search'+param;
    dialog.value = false;
  };

  const closeModalApply = () => {
    dialog.value = false;
    store.isShowModalSearch = false;
    emit("closeModalApply");
  };

  if (route.name === `${ROUTE_NAME.CUSTOMER.SPACE_DETAIL}`) {
   listActionHeader.pop();
  }

  return {
    listActionHeader,
    handleModel,
    dialog,
    listPeople,
    formResearch,
    handelToResearch,
    closeModalApply,
    store
  };
},
};
</script>

<style lang="scss" scoped>
@import "./HeaderMenu.scss";
.wrapper {
flex-wrap: wrap;
@media all and (max-width: 768px) {
  flex-wrap: nowrap;
}
}
.header-menu {
ul {
  padding: 10px;
  text-align: left;
  display: inline-block;
  margin: 0;
  white-space: nowrap;
  list-style-type: none;
  &::after {
  padding: 0 10px 0 0;
  content: "";
  border-right: 1px solid #444;
  }
  li {
  position: relative;
  font-size: 14px;
  display: inline-block;
  padding: 0 0.05em 0.25em;
  margin: 0 0.75rem 0;
  border-bottom: 2px dotted #747474;
  }
  a {
  text-decoration: none;
  color: #222;
  }
}
@media all and (max-width: 768px) {
  ul {
  padding: 0;
  margin: 0;
  &::after {
      display: none;
  }
  li {
      font-size: 12px;
      display: block;
      padding: 0 0.05em 0.25em;
      margin: 0.25em 0;
      border-bottom: 1px dotted #747474;
  }
  }
}
@media all and (max-width: 1280px) {
  ul {
  padding: 0;
  margin: 0;
  &::after {
      display: none;
  }
  }
}
}
@media all and (max-width: 1280px) {
.header-action__button.button-apply {
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  right: 0;
  border-radius: 0;
  border: none;
  min-width: 40px;
  height: 40px;
}
.icon-apply::before {
  content: "\F05AD";
  color: white;
}
}
@media all and (max-width: 768px) {
.v-overlay.v-overlay--active.v-theme--light.v-locale--is-ltr.v-dialog.v-dialog--scrollable.apply-action__box.v-overlay--scroll-blocked {
  padding: 0;
}
.header-action__button.button-apply {
  top: 0;
  background: rgba(0, 0, 0, 0.4);
  right: 0;
  border-radius: 0;
  border: none;
  min-width: 40px;
  height: 40px;
}
.icon-apply::before {
  content: "\F05AD";
  color: white;
}
.search-apply {
  display: flex;
  flex-direction: column;
  transform: translateY(98px);
}
.v-card.v-theme--light.v-card--density-default.v-card--variant-elevated.header-action__card {
  padding: 0 40px;
}
.search-content {
  margin-bottom: 10px;
}
.v-dialog .v-overlay__content {
  width: 98%;
}
}
</style>

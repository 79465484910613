<template>
  <div class="rounded-3xl bg-[#194165] text-base tracking-widest text-[#FFF] font-semibold px-6 py-2 cursor-pointer">
    <router-link :to="loginLink">
      <span class="bg-[#FFF] text-[#194165] rounded-full px-[5px]">
        <i class="fa fa-user"></i>
      </span>
      ログイン
    </router-link>
  </div>
</template>

<script setup>
/* LoginButton.vue */
import { ROUTER_PATH } from "@/client/const";
const loginLink = ROUTER_PATH.LOGIN;
</script>
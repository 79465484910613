<template>
<div class="global-nav bg-[#194165] hidden lg:block">
  <ul class="shortcut d-flex align-center">
    <li
      v-for="(menuItem, index) in globalMenuList"
      :key="index"
      @mouseover="handleMouseover(index)"
      @mouseleave="handleMouseleave(index)"
      class="text-sm font-semibold"
    >
      <router-link :to="menuItem.link">
        {{ menuItem.title }}
      </router-link>
      <ul
        class="sub-menu absolute"
        v-if="menuItem.subMenu.length > 0"
        v-show="isShowSubMenu & menuIndex === index"
      >
        <li
          v-for="(subMenuItem, subMenuindex) in menuItem.subMenu" :key="subMenuindex"
        >
          <span v-if="menuItem.subMenu.type === 'router'">
            <router-link :to="subMenuItem.link">
              {{ subMenuItem.title }}
            </router-link>
          </span>
          <span v-else>
            <a :href="subMenuItem.link">
              {{ subMenuItem.title }}
            </a>
          </span>
        </li>
      </ul>
    </li>
  </ul>
</div>
</template>

<script setup>
import { ref } from "vue";
import { globalMenuList } from "@/client/const/globalMenu";

const menuIndex = ref(false);
const isShowSubMenu = ref(false);

const handleMouseover = async (index) => {
  menuIndex.value = index
  isShowSubMenu.value = true
}

const handleMouseleave = async (index) => {
  menuIndex.value = index;
  isShowSubMenu.value = false;
}
</script>

<style lang="scss" scoped>

.global-nav {
  font-family: "Noto Sans JP", sans-serif;
  ul.shortcut {
    max-width: 1280px;
    list-style: none;
    margin: 0 auto;

    li {
      width: calc(100% / 7);
      text-align: center;

      a {
        display: block;
        padding: 10px 0;
        color: #fff;
        text-decoration: none;
        font-size: 1rem;
        position: relative;

        &::before{
          content: "";
          display: block;
          width: 1px;
          height: 18px;
          background: #fff;
          position: absolute;
          z-index: 1;
          left: -1px;
          top: 50%;
          transform: translateY(-50%);
        }

        &::after{
          content: "";
          display: block;
          width: 1px;
          height: 18px;
          background: #fff;
          position: absolute;
          z-index: 1;
          right: -1px;
          top: 50%;
          transform: translateY(-50%);
        }

        &:hover{
          background: rgba(17, 45, 70, 1);
        }
      }

      &+li a::before {
        display: none;
      }

      ul.sub-menu {
        background: rgba(20, 50, 76, 0.8);
        width: 450px;
        li {
          width: 90%;
          text-align: left;
          margin: 14px;
          border-bottom: 1px dashed #fff;
          a {
            padding: 6px 0;
            &::after{
              display: none;
            }
            &::before{
              display: none;
            }
          }
        }
      }
    }
  }
}
</style>

<template>
  <div
    class="rounded-3xl bg-[#194165] text-base tracking-widest text-[#FFF] font-semibold px-4 py-2 cursor-pointer"
    @click="handlePush()"
  >
    <span class="bg-[#FFF] text-[#194165] rounded-full px-[5px]">
      <i class="fa fa-user"></i>
    </span>
    {{ text }}
  </div>
</template>

<script setup>
import { useI18n } from "vue-i18n";
import { ROUTER_PATH, TYPE_USER } from "@/client/const";
import useAuth from "@/client/store/auth";
import { storeToRefs } from "pinia";
import { getToken } from "@/client/utils/authToken";
import { useRouter } from "vue-router";

const { t } = useI18n();
const text = t("header.my_page");

const token = getToken(TYPE_USER.USER);
const store = useAuth();
const router = useRouter();
const { isAuthenticated } = storeToRefs(store);

if (token) {
  store.isAuthenticated = true;
}

const handlePush = () => {
  router.push(isAuthenticated ?  ROUTER_PATH.MY_PAGES : ROUTER_PATH.LOGIN)
}
</script>
<template>
  <div class="drawer-menu">
    <!-- header -->
    <div class="drawer-menu__header">
      <img :src="ImageResizing(time_sharing_logo, 20)" class="logo fill-white" width="180" height="45" alt="timesharing logo" />
    </div>

    <!-- main -->
    <div
      v-for="(drawerNavMenu, index) in drawerNavMenus"
      :key="index"
      class="drawer-menu__main px-2 my-4"
    >
      <div class="text-base font-bold mb-2">{{ drawerNavMenu.title }}</div>
      <ul class="px-4 w-full items-end">
        <li
          v-for="(menuItem, index) in drawerNavMenu.item"
          :key="index"
          class="drawer-menu__main__item text-sm my-2 pb-2 cursor-pointer"
        >
          <div
            v-if="menuItem.subMenu.length > 0 "
            @click="handleOpenMenu(menuItem.id)"
          >
            <div class="flex justify-between items-center">
              <div>
                {{ menuItem.title }}
              </div>
              <div>
                <img
                  :src="arrowSvg" alt="arrorw"
                  :class="{
                    'arrow-top':isShowSpaceSearchSubMenu & spaceSearchIndex === index,
                    'arrow-buttom ':!(isShowSpaceSearchSubMenu & spaceSearchIndex === index)
                  }"
                  width="8px" height="8px"
                />
              </div>
            </div>
          </div>
          <div v-else>
            <router-link :to="menuItem.link">
              <div class="flex justify-between items-center">
                <div>
                  {{ menuItem.title }}
                </div>
                <div>
                  <img
                    :src="arrowSvg" alt="arrorw" width="8px" height="8px"
                  />
                </div>
              </div>
            </router-link>
          </div>
          <ul
            class="drawer-menu__main__item__submenu pl-8 list-disc"
            v-if="menuItem.subMenu.length > 0"
            v-show="menuItem.isOpen"
          >
            <li
              v-for="(subMenuItem, subMenuindex) in menuItem.subMenu" :key="subMenuindex"
              class="cursor-pointer"
            >
              <span  v-if="menuItem.subMenu.type === 'router'">
                <div class="drawer-menu__main__item py-2">
                  <router-link :to="subMenuItem.link">
                    <div class="flex justify-between items-center">
                        {{ subMenuItem.title }}
                      <div>
                        <img :src="arrowSvg" alt="arrorw" width="8px" height="8px" />
                      </div>
                    </div>
                  </router-link>
                </div>
              </span>
              <span v-else>
                <div class="drawer-menu__main__item py-2">
                  <div class="flex justify-between items-center">
                    <a :href="subMenuItem.link">
                      {{ subMenuItem.title }}
                    </a>
                    <div>
                      <img :src="arrowSvg" alt="arrorw" width="8px" height="8px" />
                    </div>
                  </div>
                </div>
              </span>
            </li>
          </ul>
        </li>
      </ul>
    </div>

    <!-- footer -->
    <div class="drawer-menu__footer text-center">
      <div
        class="my-page rounded cursor-pointer"
        @click="handlePush()"
      >
        マイページ
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive } from "vue";
import time_sharing_logo from "@/client/assets/images/timesharing.svg";
import arrowSvg from "@/client/assets/images/DrawerNav/arrow.svg";
import useAuth from "@/client/store/auth";
import { storeToRefs } from "pinia";
import { ROUTER_PATH, TYPE_USER } from "@/client/const";
import { useRouter } from "vue-router";
import { getToken } from "@/client/utils/authToken";
import { ImageResizing } from "@/client/utils";
import { DrawerNavMenu } from "@/client/const/globalMenu";


const token = getToken(TYPE_USER.USER);
const store = useAuth();
const router = useRouter();
const { isAuthenticated } = storeToRefs(store);

const isShowSpaceSearchSubMenu = ref(false);
const spaceSearchIndex = ref(false);

const drawerNavMenus = reactive(DrawerNavMenu);
if (token) {
  store.isAuthenticated = true;
}

const handleOpenMenu = (id) => {
  if(id <= 3) {
    drawerNavMenus[0].item[id-1].isOpen = !drawerNavMenus[0].item[id-1].isOpen
  } else {
    drawerNavMenus[1].item[id-4].isOpen = !drawerNavMenus[1].item[id-4].isOpen;
  }
}

const handlePush = () => {
  router.push(isAuthenticated ?  ROUTER_PATH.MY_PAGES : ROUTER_PATH.LOGIN)
}

</script>

<style lang="scss">
.drawer-menu {
  font-family: "Noto Sans JP", sans-serif;
  width: 100%;
  height: 100%;
  background: #194165;
  overflow-y: auto;
  color: #fff;
  &__header {
    padding: 10px 4px;
  }
  &__main {
    &__item {
      padding-bottom: 8px 32px;
      &:not(:last-child) {
        border-bottom: solid 1px rgba(255, 255, 255, 0.5);
      }
    }
    .arrow-top {
      -webkit-transform: rotate(270deg);
      -moz-transform: rotate(270deg);
      -o-transform: rotate(270deg);
      transform: rotate(270deg);
    }
    .arrow-buttom {
      -webkit-transform: rotate(90deg);
      -moz-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      transform: rotate(90deg);
    }
  }
  &__footer {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;

    .my-page {
      padding: 8px 32px;
      border: solid 1px rgba(255, 255, 255);
    }
  }
}
</style>

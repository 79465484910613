<template>
  <div class="search" :style="{ position: props.searchBarStyle?.position }">
    <v-container>
      <div class="search-box d-flex justify-center">
        <div class="search-content search-content-location">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="20"
            viewBox="0 0 14 20"
            fill="none"
          >
            <path
              d="M7 0C3.13 0 0 3.13 0 7C0 12.25 7 20 7 20C7 20 14 12.25 14 7C14 3.13 10.87 0 7 0ZM2 7C2 4.24 4.24 2 7 2C9.76 2 12 4.24 12 7C12 9.88 9.12 14.19 7 16.88C4.92 14.21 2 9.85 2 7Z"
              fill="#B4B4B4"
            />
            <path
              d="M7 9.5C8.38071 9.5 9.5 8.38071 9.5 7C9.5 5.61929 8.38071 4.5 7 4.5C5.61929 4.5 4.5 5.61929 4.5 7C4.5 8.38071 5.61929 9.5 7 9.5Z"
              fill="#B4B4B4"
            />
          </svg>
          <select
            v-model="formResearch.group_area"
            name="parentCategory"
            class="select-location select-bg"
            id="select-group-area"
            :style="formResearch.group_area == '' ? 'color: #B4B4B4;' : ''"
          >
            <option style="margin: 8px; color: #222" value="">
              エリアから探す
            </option>
            <option
              class="select-option"
              :value="item.id"
              v-for="(item, index) in props.listGroupArea"
              :key="index"
              style="color: #222"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
        <div class="search-content search-content-calendar">
          <div class="search-content-calendar-input" @click="handleShowPopUp">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="20"
              viewBox="0 0 19 20"
              fill="none"
            >
              <path
                d="M16.5 2H15.5V0H13.5V2H5.5V0H3.5V2H2.5C1.39 2 0.51 2.9 0.51 4L0.5 18C0.5 19.1 1.39 20 2.5 20H16.5C17.6 20 18.5 19.1 18.5 18V4C18.5 2.9 17.6 2 16.5 2ZM16.5 18H2.5V8H16.5V18ZM16.5 6H2.5V4H16.5V6ZM14.5 11H9.5V16H14.5V11Z"
                fill="#B4B4B4"
              />
            </svg>
            <Field
              v-model="formResearch.dateTime"
              id="select-calendar"
              class="select__calendar"
              value=""
              placeholder="利用日時から探す"
              autocomplete="off"
              inputmode="none"
              readonly
              name="datetime_input"
            >
            </Field>
          </div>
          <button
            class="search-content-calendar-btn-reset 11111"
            :class="{ active: formResearch.timeEnd != '' }"
            @click="handleRemoveDate"
          >
            <i class="fa fa-times" style="color: #b4b4b4"></i>
          </button>
        </div>
        <div class="search-content search-content-usage">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="14"
            viewBox="0 0 19 14"
            fill="none"
          >
            <path
              d="M14.63 0.84C14.27 0.33 13.67 0 13 0L2 0.0100002C0.9 0.0100002 0 0.9 0 2V12C0 13.1 0.9 13.99 2 13.99L13 14C13.67 14 14.27 13.67 14.63 13.16L19 7L14.63 0.84ZM13 12H2V2H13L16.55 7L13 12Z"
              fill="#B3B3B3"
            />
          </svg>
          <select
            v-model="formResearch.category"
            name="parentCategory"
            class="select-location select-bg"
            :style="formResearch.category == '' ? 'color: #B4B4B4;' : ''"
          >
            <option style="color: #222" value="">利用用途から探す</option>
            <option
              :value="item.id"
              v-for="(item, index) in props.listCategory"
              :key="index"
              style="color: #222"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
        <div class="search-content search-content-people">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
          >
            <path
              d="M20.5 21V19C20.5 17.9391 20.0786 16.9217 19.3284 16.1716C18.5783 15.4214 17.5609 15 16.5 15H8.5C7.43913 15 6.42172 15.4214 5.67157 16.1716C4.92143 16.9217 4.5 17.9391 4.5 19V21"
              stroke="#B3B3B3"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12.5 11C14.7091 11 16.5 9.20914 16.5 7C16.5 4.79086 14.7091 3 12.5 3C10.2909 3 8.5 4.79086 8.5 7C8.5 9.20914 10.2909 11 12.5 11Z"
              stroke="#B3B3B3"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <select
            v-model="formResearch.people"
            name="people_input"
            class="select-location select-bg"
            :style="formResearch.people == '' ? 'color: #B4B4B4;' : ''"
          >
            <option style="color: #222" value="">人数から探す</option>
            <option
              :value="item.id"
              v-for="(item, index) in listPeople"
              :key="index"
              style="color: #222"
            >
              {{ item.name }}
            </option>
          </select>
        </div>  
        <v-btn
          class="search-button c-btn-search-1"
          tile
          color="#297fca"
          size="x-small"
          @click="handelToResearch"
        >
          <i
            class="fa-solid fa-magnifying-glass"
            style="color: #fff; font-size: 18px"
          ></i>
          検索する
        </v-btn>
      </div>
    </v-container>
  </div>

  <v-dialog v-model="isShowModalCalendar" class="c-popup-calendar">
    <ModalSearchCalendar
      @handleCloseModal="handleCloseModal"
      @selectDate="handleSelectDate"
      @selectTimeStart="handleSelectTimeStart"
      @selectTimeEnd="handleSelectTimeEnd"
      @handleSelectFullDateTime="handleSelectFullDateTime"
      :dataPopup="dataPopup"
    />
  </v-dialog>
</template>

<script setup>
/* eslint-disable */
import "@/client/components/SearchComponent/SearchComponent.scss";
import ModalSearchCalendar from "./ModalSearchCalendar.vue";
import { ref, defineProps } from "vue";
import { ROUTER_PATH } from "@/client/const";
import { useRoute, useRouter } from "vue-router";
import { Field } from "vee-validate";

const route = useRoute();
const router = useRouter();
const isShowModalCalendar = ref(false);
const formResearch = ref({
  grouparea: "",
  group_area: "",
  date: "",
  timeStart: "",
  timeEnd: "",
  category: "",
  people: "",
  dateTime: "",
  search_sort: "",
});
formResearch.value.group_area = route.query.group_area ?? '';
formResearch.value.grouparea = route.query.grouparea ?? '';
formResearch.value.category = route.query.category ?? "";
formResearch.value.people = route.query.people ?? "";
formResearch.value.search_sort = route.query.search_sort ?? "";
formResearch.value.timeStart = route.query.timeStart ?? "";
formResearch.value.timeEnd = route.query.timeEnd ?? "";
formResearch.value.date = route.query.date ?? "";
if (route.query.date && route.query.timeStart && route.query.timeEnd) {
  formResearch.value.dateTime =
    route.query.date + " " + route.query.timeStart + "～" + route.query.timeEnd;
}

const props = defineProps({
  listGroupArea: {
    type: Array,
    default: () => {
      return [];
    },
  },
  listCategory: {
    type: Array,
    default: () => {
      return [];
    },
  },
  searchBarStyle: {
    type: Object,
    default: () => {
      return {
        position: "",
        color: "",
        backgroundColor: "",
        colorNote: "#333333",
      };
    },
  },
});

const dataPopup = ref({
  date: "",
  timeStart: "",
  timeEnd: "",
});

const listPeople = [
  { name: "ブース(個室)", id: "1-5" },
  { name: "~20人", id: "6-20" },
  { name: "21~40人", id: "21-40" },
  { name: "41~60人", id: "41-60" },
  { name: "61~100人", id: "61-100" },
  { name: "101人~", id: "101-" },
];

const handelToResearch = () => {
  // router.push({
  //   path: `${ROUTER_PATH.RESEARCH_SPACE}`,
  //   query: {
  //     group_area: formResearch.value.group_area,
  //     date: formResearch.value.date,
  //     timeStart: formResearch.value.timeStart,
  //     timeEnd: formResearch.value.timeEnd,
  //     category: formResearch.value.category,
  //     people: formResearch.value.people,
  //   },
  // });
  if(formResearch.value.group_area) {
    formResearch.value.grouparea = props.listGroupArea.find(it => it.id == formResearch.value.group_area)?.slug
  }
  window.location.href =
    `${ROUTER_PATH.RESEARCH_SPACE}` +
    "?grouparea=" +
    formResearch.value.grouparea +
    "&date=" +
    formResearch.value.date +
    "&timeStart=" +
    formResearch.value.timeStart +
    "&timeEnd=" +
    formResearch.value.timeEnd +
    "&category=" +
    formResearch.value.category +
    "&people=" +
    formResearch.value.people +
    "&search_sort=" +
    formResearch.value.search_sort +
    "&group_area=" +
    formResearch.value.group_area

};

const handleShowPopUp = () => {
  isShowModalCalendar.value = true;
  const valueInputSelectCalendar =
    document.getElementById("select-calendar").value;
  if (valueInputSelectCalendar && valueInputSelectCalendar !== "利用日時") {
    const obj = valueInputSelectCalendar.split(" ");
    const time = obj[1].split("～");
    dataPopup.value.date = obj[0].replaceAll("/", "-");
    dataPopup.value.timeEnd = time[1];
    dataPopup.value.timeStart = time[0];
  }
};

const handleRemoveDate = () => {
  const selectCalendar = document.querySelector("#select-calendar");
  const btnRemoveValueDate = document.querySelector(
    ".search-content-calendar-btn-reset"
  );
  btnRemoveValueDate.classList.remove("active");
  selectCalendar.value = "";
  formResearch.value.date = "";
  formResearch.value.timeStart = "";
  formResearch.value.timeEnd = "";
  formResearch.value.dateTime = "";
  router.push({
    query: formResearch.value,
    replace: true,
  });
};

const handleCloseModal = () => {
  isShowModalCalendar.value = false;
};
const handleSelectDate = (data) => {
  formResearch.value.date = data;
};
const handleSelectTimeStart = (data) => {
  formResearch.value.timeStart = data;
};
const handleSelectTimeEnd = (data) => {
  formResearch.value.timeEnd = data;
};
const handleSelectFullDateTime = (data) => {
  formResearch.value.dateTime = data;
};
</script>

<style lang="scss" scoped>
.select__calendar {
  color: #000 !important;
  cursor: pointer;
  &::placeholder {
    color: #b4b4b4 !important;
  }
}

.select-bg {
  background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 8 12"%3E%3Cpath d="M0 4L4 8L8 4" fill="none" stroke="%23B4B4B4" stroke-width="2"/%3E%3C/svg%3E');
  background-repeat: no-repeat;
  background-position: right 5px center;
  background-size: 18px 18px;
  cursor: pointer;
  color: #222;
}
</style>
